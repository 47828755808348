import { Button, Col, Row, Typography, Tooltip, Switch, Dropdown, Space, Menu, message, Select} from "antd"; 
import React, { useEffect, useState } from "react";
import { Prompt } from 'react-router-dom';
import { DownOutlined, LockOutlined, UnlockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import confirm from "antd/lib/modal/confirm";
import ApiService from "../../../services/api-services";
import axios from "axios";

export default function OrgNotificationSettings(props) {
  const [loading, setLoading] = useState(true);
  const [orgList, setOrgList] = useState([{ org_id: '9a74cf80-5861-11ea-87c9-91170caf98ab', name: 'ALL Organizations' }]);
  const [orgID, setOrgID] = useState('9a74cf80-5861-11ea-87c9-91170caf98ab');
  const token = sessionStorage.getItem("token");
  const roleID = sessionStorage.getItem("role_id");
  const cancelToken = axios.CancelToken.source();
  const [changed, setChanged] = useState(false);

  // Initial notification settings
  const notificationSettings = {
    'SAR': { email: {enabled: false, locked: false}, sms: {enabled: false, locked: false}, in_app: {enabled: false, locked: false}},
    'SUB-SAR': { email: {enabled: false, locked: false}, sms: {enabled: false, locked: false}, in_app: {enabled: false, locked: false}},
    '@-SAR': { email: {enabled: false, locked: false}, sms: {enabled: false, locked: false}, in_app: {enabled: false, locked: false}},
  };

  // State for orgNotifications and originalOrgNotifications
  const [orgNotifications, setOrgNotifications] = useState(notificationSettings);
  const [originalOrgNotifications, setOriginalOrgNotifications] = useState(notificationSettings);

  // Load organizations and notifications on initial render
  useEffect(() => {
    if (!token) {
      props.history.push("/login");
      window.location.reload(true);
    }
    loadOrgList();
    loadOrgNotifications(orgID);
  }, []);

  // Loads the list of organizations
  const loadOrgList = async () => {
    try {
      setLoading(true);
      const Orglist = await ApiService.adddetails.getOrgList(cancelToken);
      if (Orglist?.status === 200) {
        const sortedOrgList = Orglist.data.sort((a, b) => a.name.localeCompare(b.name));
        setOrgList([...orgList, ...sortedOrgList]);
      }
    } catch (error) {
      message.error("Fetching organizations did not go as expected, try again");
    } finally {
      setLoading(false);
    }
  };

  // Handles organization change
  const handleOrgChange = (orgID) => {
    setOrgID(orgID);
    loadOrgNotifications(orgID);
  };

  // Create organization dropdown menu
  const organizations = (
      orgList
        .filter((organization) => organization.name !== 'Afya') // Filter out "Afya" since it's used as all organizations
        .map((organization) => ({'value': organization.org_id, 'label': organization.name}))
  );

  // Loads organization notifications
  const loadOrgNotifications = async (orgID) => {
    try {
      setLoading(true);
      const modality_mapping = await ApiService.general.getModalityMapping(cancelToken);
      let fetchedOrgNotifications = await ApiService.general.getOrgNotificationSettings({ role_id: roleID, org_id: orgID }, cancelToken);
      const notificationSettings = {};
      for (const rule of fetchedOrgNotifications.data) {
        const trigger = rule.trigger;
        const singleSetting = {};
        for (const mapping of modality_mapping.data) {
          const { modality, position } = mapping;
          singleSetting[modality] = {};
          singleSetting[modality].enabled = rule.enabled[position];
          singleSetting[modality].locked = rule.locked[position];
        }
        notificationSettings[trigger] = singleSetting;
      }
      const originalNotificationSettings = JSON.parse(JSON.stringify(notificationSettings));
      if (fetchedOrgNotifications?.status === 200) {
        setOrgNotifications({ ...notificationSettings });
        setOriginalOrgNotifications({ ...originalNotificationSettings });
      }

    } catch (error) {
      message.error("Settings could not be loaded, try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Switch changes
  const handleSwitchChange = (trigger, modality) => {
    setOrgNotifications((previous) => {
      previous[trigger][modality].enabled = !previous[trigger][modality].enabled
      return { ...previous }
    });
  };

  // Toggle lock functions
  const toggleLock = (trigger, modality) => {
    let newOrgNotifications = { ...orgNotifications };
    newOrgNotifications[trigger][modality].locked = !newOrgNotifications[trigger][modality].locked;
    setOrgNotifications(newOrgNotifications);
  };

  // Save changes
  const handleSave = async () => {
    try {
      setLoading(true);
      let newOrgNotifications = { ...orgNotifications };
      delete newOrgNotifications.in_app_lock;

      const data = {
        role_id: roleID,
        org_id: orgID,
        settings: newOrgNotifications
      };
      const response = await ApiService.general.updateOrgNotificationSettings(data);
      if (response?.status === 200) {
        message.success("Settings saved successfully");
        // Update originalOrgNotifications to the new saved settings
        setOriginalOrgNotifications(newOrgNotifications);
        setChanged(false);
      }
    } catch (error) {
      message.error("Settings could not be saved, try again");
    } finally {
      setLoading(false);
      loadOrgNotifications(orgID);
    }
  };

  // Deep comparison function
  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (typeof obj1 != 'object' || obj1 === null || typeof obj2 != 'object' || obj2 === null)
      return false;

    let triggers = Object.keys(obj1).sort();
    for (const trigger of triggers) {
      let modalities = Object.keys(obj1[trigger]).sort();
      for (const modality of modalities) {
        if (!obj2[trigger] || !obj2[trigger][modality]) return false;
        if (obj1[trigger][modality].enabled !== obj2[trigger][modality].enabled) return false;
        if (obj1[trigger][modality].locked !== obj2[trigger][modality].locked) return false
      }
    }
    return true;
  };

  // Compare orgNotifications with originalOrgNotifications
  const compareOrgNotifications = () => {
    return deepEqual(orgNotifications, originalOrgNotifications);
  };

  // Check if orgNotifications has changed
  useEffect(() => {
    if (originalOrgNotifications) {
      setChanged(!compareOrgNotifications());
    }
  }, [orgNotifications, originalOrgNotifications]);

  // Confirm changes before leaving
  const confirmChange = () => {
    confirm({
      title: 'Do you want to save the changes?',
      onOk() {
        handleSave();
      },
      onCancel() {
      },
    });
  };

  return (
    <div className="notification-settings">
      <Prompt
        when={changed}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Row gutter={[0, 48]}>
        <Col span={18}>
          <h3 className="heading-font">Organization Notification Settings </h3>
        </Col>
        <Col span={6}>
          <Tooltip title="Locking defaults means that lower level roles will not be able to modify these settings. These settings will take priority and be selected over other roles' selections." placement="bottom">
            <QuestionCircleOutlined style={{ fontSize:"36px", textAlign: "right"}}/>
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={8} className="notification-col" style={{ 'paddingLeft': '15px' }}>
          <Select 
            showSearch
            options={organizations}
            optionFilterProp="label"
            onChange={(value) => handleOrgChange(value)}
            value={orgID}
            style={{ width: '15rem', alignContent: 'center' }}
            className="org-select"
            >
          </Select>
        </Col>
        <Col span={4}>
          <Typography>Email</Typography>
        </Col>
        <Col span={4}>
          <Typography>SMS</Typography>
        </Col>
        <Col span={4}>
          <Typography>In-App</Typography>
        </Col>
      </Row>

      {/* First Exchange (SAR) */}
      <Row gutter={[16, 48]}>
        <Col span={8} className="notification-col">
        <Tooltip title="Notification triggers upon chat creation and the first response the Responder sends." placement="right">
          <Typography>Chat Creation and First Responses</Typography>
        </Tooltip>
        </Col>
        {/* SAR email */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['SAR'].email.enabled}
            onChange={() => handleSwitchChange('SAR', 'email')}
            disabled={orgNotifications['SAR'].email.locked}
          />
          <Tooltip title={orgNotifications['SAR'].email.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>
            <Button
              type="text"
              icon={orgNotifications['SAR'].email.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('SAR', 'email')}
            />
          </Tooltip>
        </Col>
        {/* SAR SMS */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['SAR'].sms.enabled}
            onChange={() => handleSwitchChange('SAR', 'sms')}
            disabled={orgNotifications['SAR'].sms.locked}
          />
          <Tooltip title={orgNotifications['SAR'].sms.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>
            <Button
              type="text"
              icon={orgNotifications['SAR'].sms.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('SAR', 'sms')}
            />
          </Tooltip>
        </Col>
        {/* SAR In-App */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={true}
            disabled={true}
          />
          <Tooltip title="To Be Implemented">
            <Button
              type="text"
              icon={<LockOutlined style={{ color: 'grey' }} />}
            />
          </Tooltip>
        </Col>
      </Row>

      {/* Subsequent Messages (SubSAR) */}
      <Row gutter={[16, 48]}>
        <Col span={8} className="notification-col">
        <Tooltip title="Notification triggers for messages that are not the first responses and are not special events, eg. adding recommendation, patients, specialists, etc." placement="right">
          <Typography>Subsequent Exchanges</Typography>
        </Tooltip>
        </Col>
        {/* SubSAR email */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['SUB-SAR'].email.enabled}
            onChange={() => handleSwitchChange('SUB-SAR', 'email')}
            disabled={orgNotifications['SUB-SAR'].email.locked}
          />
          <Tooltip title={orgNotifications['SUB-SAR'].email.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>

            <Button
              type="text"
              icon={orgNotifications['SUB-SAR'].email.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('SUB-SAR', 'email')}
            />
          </Tooltip>
        </Col>
        {/* SubSAR SMS */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['SUB-SAR'].sms.enabled}
            onChange={() => handleSwitchChange('SUB-SAR', 'sms')}
            disabled={orgNotifications['SUB-SAR'].sms.locked}
          />
          <Tooltip title={orgNotifications['SUB-SAR'].sms.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>
            
            <Button
              type="text"
              icon={orgNotifications['SUB-SAR'].sms.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('SUB-SAR', 'sms')}
            />
          </Tooltip>
        </Col>
        {/* SubSAR In-App */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={true}
            disabled={true}
          />
          <Tooltip title="To Be Implemented">
            <Button
              type="text"
              icon={<LockOutlined style={{ color: 'grey' }} />}
            />
          </Tooltip>
        </Col>
      </Row>

      {/* Recommendations and Events (@SAR) */}
      <Row gutter={[16, 48]}>
        <Col span={8} className="notification-col">
        <Tooltip title="Notification triggers upon giving recommendations, adding patients, specialists, and other events." placement="right">
          <Typography>Recommendations and Events</Typography>
        </Tooltip>
        </Col>
        {/* @SAR email */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['@-SAR'].email.enabled}
            onChange={() => handleSwitchChange('@-SAR', 'email')}
            disabled={orgNotifications['@-SAR'].email.locked}
          />
          <Tooltip title={orgNotifications['@-SAR'].email.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>

            <Button
              type="text"
              icon={orgNotifications['@-SAR'].email.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('@-SAR', 'email')}
            />
          </Tooltip>
        </Col>
        {/* @SAR SMS */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={orgNotifications['@-SAR'].sms.enabled}
            onChange={() => handleSwitchChange('@-SAR', 'sms')}
            disabled={orgNotifications['@-SAR'].sms.locked}
          />
          <Tooltip title={orgNotifications['@-SAR'].sms.locked ? "Unlock default for lower level roles" : "Lock default for lower level roles"}>
            <Button
              type="text"
              icon={orgNotifications['@-SAR'].sms.locked ? <LockOutlined style={{ color: 'red' }} /> : <UnlockOutlined style={{ color: 'green' }} />}
              onClick={() => toggleLock('@-SAR', 'sms')}
            />
          </Tooltip>
        </Col>
        {/* @-SAR In-App */}
        <Col span={4}>
          <Switch
            checkedChildren='On'
            unCheckedChildren='Off'
            checked={true}
            disabled={true}
          />
          <Tooltip title="To Be Implemented">
            <Button
              type="text"
              icon={<LockOutlined style={{ color: 'grey' }} />}
            />
          </Tooltip>
        </Col>
      </Row>

      {changed &&
        <Row style={{ display: 'flex', 'justify-content': 'center' }} className='button-padding'>
          <Button
            title = "Save the changes made"
            className='btn-type' id='' type='primary'
            onClick={() => confirmChange()}
          >
            Save
          </Button>
          <Button
            title = "Reset the changes made"
            className='btn-type' id='' type='primary' danger
            onClick={() => loadOrgNotifications(orgID)}
          >
            Reset
          </Button>
        </Row>
      }
    </div>
  );
}
